import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import logo from '@/assets/logo.svg';
import { COLORS } from '@/constants/colors';
import { marketingHome, marketingTerms } from '@/constants/links';
import { Media } from '@/constants/styledTheme';
import { COLORS as COLORS_OLD, fontAvenirBold } from '@/constants/styles';
import { Anchor, Link } from '@/elements/Anchor';
import { AnchorButton, Button, LinkButton } from '@/elements/Button';
import { Box, Flex } from '@/elements/Div';
import { CheckboxInput, Input, Label, SelectComponent, StyledInput } from '@/elements/forms';
import { Header } from '@/elements/Header';
import { Img } from '@/elements/Img';
import { Paragraph } from '@/elements/Paragraph';
import { Span } from '@/elements/Span';
import { loginUrl, registerUrl } from '@/routes';
import { IntercomOpen } from '@/utils/intercom';

import { UsernameInput } from './UserNameInput';

const StyledAuthMainHeader = styled(Header)`
  font-size: 24px;
  line-height: 36px;
`;

export const AuthMainHeader: React.FC<React.PropsWithChildren> = ({ children }) => (
  <StyledAuthMainHeader type="h3" mt="15px" color="fillBlackBlack" textAlign="center">
    {children}
  </StyledAuthMainHeader>
);

export const SmallCheckboxInput = styled(CheckboxInput)`
  width: 12px;
  height: 12px;
  margin: 0;
  margin-right: 10px;
`;

export const AuthCheckboxLabel = styled.label`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`;

const labelCss = css`
  margin-bottom: 4px;
  color: ${COLORS.Neutral[600]};
  font-size: 12px;
`;

export const AuthLabel = styled(Label)(labelCss);

export const AuthFormInput = styled(Input)`
  ${Label} {
    ${labelCss}
  }

  ${StyledInput} {
    padding: 6px 10px;
    font-size: 14px;

    &::placeholder {
      color: ${COLORS_OLD.disabledBlackOutlineButton};
    }
  }
`;

export const AuthFormSelect = styled(SelectComponent)``;

export const AuthFormSelectStyle = css`
  ${Label} {
    ${labelCss}
  }

  ${AuthFormSelect} {
    padding: 0px;
    height: 38px;
    font-size: 12px;
  }
`;

export const AuthUserNameInput = AuthFormInput.withComponent(UsernameInput);

export const AuthPill = styled(Box)`
  ${fontAvenirBold}
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  border-radius: 2000px;
  padding: 7px 16px 5px;
`;

export const AuthStyledButton = styled(Button)`
  font-size: 14px;
  line-height: 18px;
  border-radius: 7px;
  height: 36px;
`;

export const AuthBigButton = styled(AuthStyledButton)`
  display: inline-block;
  ${({ btnType }) =>
    btnType === 'blackOutline' &&
    css`
      border-color: ${COLORS.Neutral[100]};
      color: ${COLORS.Neutral[1000]};

      &:hover:not(:disabled) {
        background-color: #1d1d1d;
      }
}`}
`;

export const AuthBigLinkButton = AuthBigButton.withComponent(LinkButton);

export const AuthBigAnchorButton = AuthBigButton.withComponent(AnchorButton);

const Page = styled(Flex)`
  background-color: ${COLORS.Neutral[0]};
  min-height: 100vh;
  height: auto;
  flex-direction: column;
  align-items: center;
`;

const Card = styled(Box)`
  width: 100%;
  background-color: ${COLORS_OLD.white};
`;

const linkStylesBase = css`
  font-size: 14px;
  line-height: 28px;
  text-decoration: underline;
`;

const linkStyles = css`
  ${linkStylesBase}
  color: ${COLORS.Neutral[600]};

  &:visited {
    color: ${COLORS.Neutral[600]};
  }

  &:hover {
    color: ${COLORS.Neutral[800]};
  }
`;

const UnderlinedLink = styled(Link)(linkStyles);

const UnderlinedAnchor = styled(Anchor)(linkStyles);

const linkStylesPurple = css`
  ${linkStylesBase}
  color: ${COLORS.Purple[600]};

  &:visited {
    color: ${COLORS.Purple[600]};
  }

  &:hover {
    color: ${COLORS.Purple[800]};
  }
`;

const UnderlinedLinkPurple = styled(Link)(linkStylesPurple);

export const AuthLayout: React.FC<
  React.PropsWithChildren<{
    headerRight?: string;
    headerRightExtra?: string;
    headerCenter?: string;
    loginCTA?: boolean;
    hideCTA?: boolean;
  }>
> = ({ children, headerRight, headerRightExtra, headerCenter, loginCTA = false, hideCTA = false }) => {
  const ctaUrl = loginCTA ? loginUrl() : registerUrl();
  const ctaButton = loginCTA ? 'Sign In' : 'Sign Up';
  const ctaText = loginCTA ? 'Already Have an Account?' : "Don't Have an Account?";

  const defaultHeaderRight = (
    <Media greaterThan="xs">
      <Paragraph fontSize="14px" color={COLORS.Neutral[600]}>
        {ctaText}
        <Span color={COLORS.Purple[600]}>
          <UnderlinedLinkPurple ml="10px" href={ctaUrl} bold={true} hover="underline">
            {ctaButton}
          </UnderlinedLinkPurple>
        </Span>
      </Paragraph>
    </Media>
  );

  const headerRightContent = headerRight && (
    <Box flex="1">
      <Media lessThan="l">
        <Paragraph color="white" fontSize="16px" lineHeight="1" bold={true} textAlign="right">
          {headerRight}
        </Paragraph>
      </Media>
      <Media greaterThanOrEqual="l">
        <Paragraph color="white" fontSize="16px" lineHeight="1" bold={true} textAlign="right">
          {headerRight} {headerRightExtra && '- ' + headerRightExtra}
        </Paragraph>
      </Media>
    </Box>
  );

  const centerHeaderContent = headerCenter && (
    <Media greaterThan="s">
      <Box flex="1">
        <Paragraph color="white" fontSize="16px" lineHeight="1" bold={true} textAlign="center">
          {headerCenter}
        </Paragraph>
      </Box>
    </Media>
  );

  return (
    <Page>
      <Flex width="100%" justifyContent="space-between" p={{ _: '20px', s: '20px 50px' }} alignItems="center">
        <Box flex="1">
          <Box width={{ _: '100px', s: '117px' }}>
            <Anchor href={marketingHome.url()}>
              <Img src={logo} alt="upbound" width="100%" id="upbound_logo-auth" />
            </Anchor>
          </Box>
        </Box>
        {centerHeaderContent}
        {headerRightContent ?? defaultHeaderRight}
      </Flex>

      <Flex
        maxWidth="400px"
        width="100%"
        alignItems="center"
        flexDirection="column"
        mt={{ _: '25px', m: '60px' }}
        mb="60px"
        px="15px"
      >
        <Card mb="100px">
          <Box>{children}</Box>
        </Card>
        {!hideCTA && (
          <Paragraph
            color={COLORS.Neutral[600]}
            fontSize="14px"
            lineHeight="28px"
            mt="30px"
            mb={{ _: '40px' }}
            textAlign="center"
          >
            {ctaText}{' '}
            <UnderlinedLink href={ctaUrl} hover="underline">
              {ctaButton}
            </UnderlinedLink>
          </Paragraph>
        )}
        <Flex flexDirection={{ _: 'column', xs: 'row' }} alignItems="center" mt={hideCTA ? '40px' : '0'}>
          <UnderlinedAnchor
            href={marketingTerms.url()}
            target="_blank"
            rel="noopener noreferrer"
            mr={{ _: '0', xs: '30px' }}
            hover="underline"
          >
            Terms of Service
          </UnderlinedAnchor>
          <UnderlinedAnchor
            href="#"
            rel="noopener noreferrer"
            onClick={e => {
              e.preventDefault();
              IntercomOpen();
            }}
            mr={{ _: '0', xs: '30px' }}
            hover="underline"
          >
            Support
          </UnderlinedAnchor>
          <Span fontSize="14px" lineHeight="28px" color={COLORS.Neutral[600]}>
            © 2022 Upbound Inc
          </Span>
        </Flex>
      </Flex>
    </Page>
  );
};
