import { FieldProps, FormikProps } from 'formik';
import React, { useCallback, useEffect } from 'react';

import { Input, InputProps } from '@/elements/forms';
import * as api from '@/restApi';
import { useDebounce } from '@/utils/hooks';

export const UsernameInput: React.FC<
  InputProps & {
    field: FieldProps['field'];
    form: FormikProps<{ username: string }>;
    label: string;
    setNameAvailable: (status: boolean) => void;
    setNameError?: (error: api.ApiError) => void;
    setCheckedName?: (status: boolean) => void;
    setCheckingName?: (status: boolean) => void;
  }
> = ({
  setNameAvailable,
  setNameError = () => undefined,
  setCheckedName = () => undefined,
  setCheckingName = () => undefined,
  ...props
}) => {
  const { field, form } = props;
  const { value } = field;
  const debouncedValue = useDebounce(value, 1000);

  const checkName = useCallback(
    async (v: string) => {
      try {
        const available = await api.accountAvailable(v);

        setCheckedName(true);
        setCheckingName(false);

        if (!available) {
          setNameAvailable(false);
          return;
        }

        setNameAvailable(true);
        return;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        setNameError(err);
        setCheckedName(true);
        setCheckingName(false);
        setNameAvailable(false);
        return;
      }
    },
    [setCheckedName, setCheckingName, setNameAvailable, setNameError],
  );

  useEffect(() => {
    // Don't check for empty string or whitespace string
    if (!value || !value.replace(/\s/g, '').length) {
      setCheckingName(false);
      setCheckedName(false);
      setNameAvailable(false);
      return;
    }

    setCheckingName(true);

    if (!debouncedValue || form.errors[field.name] || form.isValidating) {
      return;
    }

    checkName(debouncedValue);
  }, [
    checkName,
    debouncedValue,
    field.name,
    form.errors,
    form.isValidating,
    setCheckedName,
    setCheckingName,
    setNameAvailable,
    value,
  ]);

  useEffect(() => {
    setCheckedName(false);
  }, [setCheckedName, value]);

  return <Input trim={true} {...field} {...props} />;
};
