import { useRouter } from 'next/router';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import { PrepopulationValues } from '@/@types/models';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useDebounce(value: any, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function useExpandable() {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (!isExpanded) {
      if (contentHeight !== 0) {
        setContentHeight(0);
        return;
      }

      return;
    }

    if (!contentRef || !contentRef.current || !contentRef.current.scrollHeight) {
      return;
    }

    setContentHeight(contentRef.current.scrollHeight);
  }, [isExpanded, contentRef, contentHeight]);

  return { isExpanded, setExpanded, contentRef, contentHeight };
}

export function useErrorParam(): [string | undefined, (value: string) => void] {
  const router = useRouter();
  const queryParams = router.query;
  const queryParamError = queryParams.error;
  const [currentError, setCurrentError] = useState<string>();

  useEffect(() => {
    if (typeof queryParamError === 'string') {
      const search = new URLSearchParams(window.location.search);
      search.delete('error');
      if (queryParamError !== currentError) {
        setCurrentError(queryParamError);
      }
    }
  }, [currentError, queryParamError, queryParams]);

  return [currentError, setCurrentError];
}

export function usePrepopulationValues(): [PrepopulationValues, (value: PrepopulationValues) => void] {
  const defaultPrepopulationValues: PrepopulationValues = {
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    country: undefined,
    state: undefined,
  };

  const [prepopulationValues, setPrepopulationValues] = useState(defaultPrepopulationValues);
  const [hasCheckedStorage, setHasCheckedStorage] = useState(false);

  useEffect(() => {
    const prepopulationValuesString = sessionStorage.getItem('prepopulationValues');

    if (typeof prepopulationValuesString === 'string') {
      try {
        const prepopulationValuesObj = JSON.parse(prepopulationValuesString);
        setPrepopulationValues(prepopulationValuesObj);
      } catch (e) {
        console.error(e);
      }
    }

    setHasCheckedStorage(true);
  }, []);

  useEffect(() => {
    if (!hasCheckedStorage) {
      return;
    }

    const prepopulationValuesString = JSON.stringify(prepopulationValues);

    if (sessionStorage.getItem('prepopulationValues') !== prepopulationValuesString) {
      sessionStorage.setItem('prepopulationValues', prepopulationValuesString);
    }
  }, [prepopulationValues, hasCheckedStorage]);

  return [prepopulationValues, setPrepopulationValues];
}

export function useCountdown(initialTimeRemaining: number): [number, Dispatch<SetStateAction<number>>] {
  const [timeRemaining, setTimeRemaining] = useState(initialTimeRemaining);

  useEffect(() => {
    if (timeRemaining <= 0) {
      return;
    }

    const countdownInterval = setInterval(() => {
      setTimeRemaining(prevTimeRemaining => prevTimeRemaining - 1);
    }, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  }, [timeRemaining]);

  return [timeRemaining, setTimeRemaining];
}
