import styled from '@emotion/styled';
import * as styledSystem from 'styled-system';

import { TextColors } from '@/constants/colors';

import { COLORS as COLORS_OLD, fontAvenirBold, fontAvenirRoman, shouldForwardProp } from '../constants/styles';
interface SpanProps
  extends styledSystem.ColorProps,
    styledSystem.LayoutProps,
    styledSystem.SpaceProps,
    styledSystem.TypographyProps {
  color?: keyof typeof COLORS_OLD | TextColors;
  bold?: boolean;
}

const baseStyledSystem = styledSystem.compose(
  styledSystem.color,
  styledSystem.layout,
  styledSystem.space,
  styledSystem.typography,
);

export const Span = styled('span', { shouldForwardProp })<SpanProps>`
  ${({ bold }) => (bold ? fontAvenirBold : fontAvenirRoman)}
  color: ${props => props.color ?? COLORS_OLD.slate};
  ${baseStyledSystem}
`;
